import { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
const LogoutModal = ({closeModalLogout, base_url}) => {
       const [isPending, setIsPending] = useState(false);
       const [isLogin, setIsLogin] = useState(false);
       const history = useHistory();
       const handleSubmit = (e) => {
              e.preventDefault();
              setIsPending(true);
        axios({
            method: 'delete',
            url: base_url+'shop/sessions/'+localStorage.getItem("sessionID"),
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("accessToken")
            },
            data: null
          })
          .then(response => {
                closeModalLogout(false)
                localStorage.clear();
                setIsPending(false);
                setIsLogin(true);
                googleLogout();
                history.push("/");

          })
          .catch(error => {
                closeModalLogout(false)
                setIsPending(false);
                localStorage.clear();
                setIsPending(false);
                setIsLogin(true);
                googleLogout();
                history.push("/");
          })
       }
       return ( 
<div className="modalContainer">
<div   className="container mx-auto login-modal-dialog" >
    <div  className="modal-content border-0 shadow"></div>
              <div className="modal-items">
                      <div  className="modal-dialog modal-dialog-centered">
                              <div  className="modal-content border-0 shadow" >
                                   {isLogin}
                                   {isPending}
                                  <button  type="button" data-bs-dismiss="modal" onClick={() =>closeModalLogout(false)} aria-label="Close" className="close"><span  aria-hidden="true">×</span></button>
                                  <div  className="items p-5 text-center">
                                      <div >
                                          <h5 >Logout?</h5>
                                          <h6  className="pt-2 pb-3">Are you sure you want to logout?</h6>
                                      </div>
                                      <div  className="d-flex justify-content-center">
                                          <button  type="button" data-bs-dismiss="modal" className="btn btn-light me-2" onClick={() => closeModalLogout(false)}>Cancel</button>
                                          <button  type="submit" data-bs-dismiss="modal" className="btn btn-danger text-white" onClick={handleSubmit} >Logout</button>
                                      </div>
                                  </div>
                                  
                              </div>
                          
                      </div>
                  </div>
                </div>
              </div>
        );
}
 
export default LogoutModal;
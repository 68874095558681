
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

const PaymentWaiting = ({altImage, base_url}) => {
    const history = useHistory();
    const PaymentWaiting = async() =>{
        await axios({
            method: 'get',
            url: base_url+'shop/payments/'+localStorage.order_number,
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('accessToken')
            }
          })
          .then(response => {
                if (response.data.data.status === 'paid') {
                    history.push("/orders");
                }
               
          })
          .catch(error => {
                // console.log(error);
          });
    }
    useEffect(() => {
        const interval = setInterval(() => {
            PaymentWaiting();
        }, 1000)
        return () => clearInterval(interval)
      });

    return ( 
        <section  className="container-fluid">
        <div  className="container">
                                    <div  className="wrapper wrapper-content animated fadeInRight">
                                                <div className="card mt-4" >
                                                    <div>
                                                        <div  className="card-header"><h6 >Awaiting Payment</h6></div>
                                                        <div  className="card-body">
                                                        <div className="spinner-border" role="status">
                                                        <span className="sr-only text-lg">Loading...</span>
                                                        </div> 
                                                        <p>please insert your mobile money pin</p>                
                                                        </div>
                                                    </div>
                                                <div className="pt-4 d-flex justify-content-center">
                                                    <Link to ="/orders" className="btn btn-danger text-white ms-3 w-lg-25">Cancel Order</Link>    
                                                </div>
                                                </div>
            </div>
        </div>
    </section>
     );
}
 
export default PaymentWaiting;

import LoginModal from "./LoginModal";
import { GoogleOAuthProvider } from '@react-oauth/google';
import CartSide from "./CartSide";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from 'react-hook-form';
import mobileMoney from '../assets/media/mobile.jpg';
import toast from 'react-hot-toast';

const CheckOut = ({altImage, cartItems, currency, base_url, setCartItems}) => {
    const history = useHistory();
    if(cartItems.length === 0){
                history.push("/catalog");
    }
       const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price.replace(/,/g,''), 0);
       const navbarLogin = localStorage.getItem('accessToken') !== null;
       const [hide, setHide] = useState(false);
       const [loading, setLoading] = useState(false);
       const[fullName, setFullName] = useState('');
       const[emailAddress, setEmailAddress] = useState('');
       const[phoneNo, setPhoneNo] = useState('');
       const[address, setAddress] = useState('');
       const[ paymentNo, setPaymentNo ] = useState('');
       const notify = () => toast('Please input your mobile money pin to finish payment.');

       useEffect(() => {
              const fetchProfiledata = async() => {
                     await axios.get(base_url+'shop/users',{
                             headers: {
                                      "Content-Type": "application/json",
                                      "Authorization": localStorage.getItem('accessToken')
                             }
                     }).then(response => {
                                   setFullName(response.data.data.user[0].name);
                                   setEmailAddress(response.data.data.user[0].email);
                                   setPhoneNo(response.data.data.user[0].phone);
                                   setAddress(response.data.data.user[0].address);
                     }).catch(error => {
                                //    console.log(error);
                     })
              }
                     fetchProfiledata();
              },[base_url]);
            
              const {
                register,
                handleSubmit,
                formState: { errors },
             } = useForm(); 

              const ItemSubmit = async(orderid) =>{
                    for(let i = 0; i < cartItems.length; i++){
                        const data ={
                            productid: cartItems[i].id,
                            quantity: cartItems[i].qty,
                            orderid: orderid
                        }
                        await axios({
                                method: 'post',
                                url: base_url+'shop/orderItems',
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": localStorage.getItem('accessToken')
                                },
                                data: JSON.stringify(data)
                            }).then(response => {
                                setCartItems([]);
                                history.push("/payment");
                            }).catch(error => {
                                // console.log(error);
                            })
                    }    
                    }

         const onSubmit = async(rawData) => {
            setLoading(true);
            const data = { 
                itemsPrice: itemsPrice,
                paymentNo: rawData.paymentNo
             };
            // console.log(data);
            
                await axios({
                    method: 'post',
                    url: base_url+'shop/orders',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem('accessToken')
                    },
                    data: JSON.stringify(data)
                }).then(response => {
                    // console.log(response)
                    notify();
                    ItemSubmit(response.data.data.order[0].id);
                    localStorage['order_number'] = response.data.data.order[0].id;
                }).catch(error => {
                    // console.log(error);
            setLoading(false);
            })
                
         } 
       
       return ( 

              <section  className="container-fluid" >
                    {/* <Toaster
                        position="bottom-center"
                        reverseOrder={false}
                        gutter={18}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                            // Define default options
                            className: '',
                            duration: 2000,
                                
                            // Default options for specific types
                            success: {
                            duration: 2000,
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                     }}
                    /> */}
                            <div  className="container" >
                                <div  className="wrapper wrapper-content animated fadeInRight mt-5" >                        
                                    <div  className="body" >
                                        <div className="row" >
                                            <div className="col" hidden = {hide} >
                                                <div className="card" >
                                                    <div>
                                                        <div  className="card-header"><h6 >Delivery address details</h6></div>
                                                        <div  className="card-body">
                                                        <div  className="row g-3">
                                                               <div className="col-12">Name: { fullName }</div>
                                                               <div className="col-12 m-0">Phone: { phoneNo }</div>
                                                               <div className="col-12 m-0">Email: { emailAddress }</div>
                                                               <div className="col-12 m-0">Address: { address }</div>
                                                               <Link to="/profile" className="col categories-preview">
                                                               <button className="btn btn-link link">Edit Address</button>
                                                               </Link>
                                                        </div>                        
                                                        </div>
                                                    </div>
                                                <div className="pt-4 d-flex justify-content-end">
                                                    <button className="btn btn-success text-white ms-3 w-lg-25" onClick ={()=> setHide(true)} >Next Step</button>    
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col" hidden ={!hide} >
                                                <div className="card" >
                                                    <div>
                                                        <div  className="card-header"><h6>Payment Details</h6></div>
                                                        <div  className="card-body">
                                                        <div  className="row g-3">
                                                        <div  className="card-body">
                                                        <div className="accordion" id="accordionExample">
                                                                <div className="card shadow-none">
                                                                    <div className="card-header" id="headingOne">
                                                                    <h2 className="mb-0">
                                                                        <button className="btn btn-link categories-preview btn-block float-start" type="button">
                                                                        Mobile Money
                                                                        </button>
                                                                    </h2>
                                                                    {/* <hr/> */}
                                                                    </div>
                                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                    <div className="card-body">
                                                                        <form noValidate="" onSubmit={handleSubmit(onSubmit)} className="ng-untouched ng-pristine ng-valid row">
                                                                        <div className="col-md-6">
                                                                        <input 
                                                                        type="tel" 
                                                                        className="form-control"
                                                                        {...register('paymentNo', { required: true })} 
                                                                        value={paymentNo} 
                                                                        onChange ={(e) => setPaymentNo(e.target.value)}
                                                                        placeholder="please enter phone number e.g. 0755876951"
                                                                        />
                                                                        {errors.paymentNo && <p className="text-center text-danger help-block">please provide a valid phone number</p>}
                                                                        <button className="btn btn-primary mt-3 float-end text-white" disabled = { loading } type="submit">Submit Order</button>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <img src={ mobileMoney } alt={ altImage } style ={{ width: "50%" }} />
                                                                            {/* <p>Input Your Mobile Money Number To Process Payment e.g. 0755876951</p> */}
                                                                        </div>
                                                                        </form>
                                                                    </div>
                                                                    </div>
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                        </div>                        
                                                    </div>
                                                    </div>
                                                                                                      
                                                    
                                                <div className="pt-4 d-flex justify-content-end">
                                                    <button className="btn btn-danger text-white ms-3 w-lg-25" onClick ={()=> setHide(false)}>Previous Step</button>    
                                                </div>
                                                </div>
                                            </div>
                                            <CartSide  base_url={base_url} altImage={altImage} checkOut ={true} cartItems={cartItems} navbarLogin = {navbarLogin} currency={currency} itemsPrice={itemsPrice} LoginModal={LoginModal} GoogleOAuthProvider={GoogleOAuthProvider} />

                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
        );
}
 
export default CheckOut;
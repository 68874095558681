import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NavUser from '../assets/media/user.svg';
import OrdersIcon from '../assets/media/orders.svg';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useEffect } from 'react';

const AccountProfile = ({altImage, base_url}) => {
            const navbarLogin = localStorage.getItem('accessToken') !== null;
            const history = useHistory();
            if (!navbarLogin){
                history.push("/");
            }
            
              const[ fullName, setFullName ] = useState('');
              const[ emailAddress, setEmailAddress ] = useState('');
              const[ phoneNo, setPhoneNo ] = useState('');
              const[checkExistPhone, setCheckExistPhone] = useState(false);
              const[checkExistEmail, setCheckExistEmail] = useState(false);
              const[Address, setAddress] = useState('');
              
              useEffect(() => {
              const fetchProfiledata = async() => {
                     await axios.get(base_url+'shop/users',{
                             headers: {
                                      "Content-Type": "application/json",
                                      "Authorization": localStorage.getItem('accessToken')
                             }
                     }).then(response => {
                                   setFullName(response.data.data.user[0].name);
                                   setEmailAddress(response.data.data.user[0].email);
                                   setPhoneNo((response.data.data.user[0].phone).substring(3));
                                   setAddress(response.data.data.user[0].address);
                                   if (response.data.data.user[0].phone !== ""){
                                          setCheckExistPhone(true)
                                   }
                                   if (response.data.data.user[0].email !== ""){
                                          setCheckExistEmail(true)
                                   }
                     }).catch(error => {
                                //    console.log(error);
                     })
              }
                     fetchProfiledata();
              },[base_url]);
       
              const {
                     register,
                     handleSubmit,
                     formState: { errors },
              } = useForm(); 
              const onSubmit = async(data) => {
                    //  console.log(data);
                     await axios({
                            method: 'patch',
                            url: base_url+'shop/users',
                            headers: {
                                     "Content-Type": "application/json",
                                     "Authorization": localStorage.getItem('accessToken')
                            },
                            data: data
                                    }).then(response => {
                                   // console.log(response);
                                  setFullName(response.data.data.user[0].name);
                                  setEmailAddress(response.data.data.user[0].email);
                                  setPhoneNo(response.data.data.user[0].phone);
                                  setAddress(response.data.data.user[0].address);
                                  
                    }).catch(error => {
                            // console.log(error);
                    })
              }
              
       return ( 
              <div className="container mt-3">
                  <div  className="wrapper wrapper-content">
                      {/* <div  className="row header"></div> */}
                      <div  className="body">
                          <div  className="row m-0 justify-content-center">
                              <div  className="col-md-4 d-none d-md-block">
                                  <ul  className="list-unstyled bg-white nav-items">
                                      <li  className="border-top my-3-"></li>
                                          <Link  to="/profile" className="categories-preview"> 
                                                 <li   className="m-1  px-5 py-2 topnav-item text-nowrap fw-bold active-nav-account-sidebar ">
                                                        <img  src={NavUser} alt={ altImage } /> Account
                                                 </li>
                                          </Link>
                                      <li  className="border-top my-3-"></li>
                                      <Link  to="/orders" className="categories-preview"> 
                                      <li  className="mb-1  px-5 py-2 topnav-item text-nowrap fw-bold ">
                                          <img  src={OrdersIcon} alt={ altImage } /> Orders
                                      </li>
                                   </Link>

                                      <li  className="border-top my-3-"></li>
                                      <Link  to="/" className="categories-preview"> 
                                      <li  className="mb-3- text-center">
                                          <button  className="btn btn-toggle align-items-center fw-bold rounded my-2 collapsed text-uppercase">Home</button>
                                      </li>
                                   </Link>
                                      
                                  </ul>
                              </div>
                              <div  className="col-12 col-md-8 col-lg-8">
                                  <div>
                                      <div className="card panel">
                                          <div className="card-body">
                                              <div className="card-title"><h4 className="text-center mt-1">Profile</h4></div>
                                              <form noValidate="" onSubmit={handleSubmit(onSubmit)} className="ng-untouched ng-pristine ng-valid">
                                                  <div className="row mb-1">
                                                      <div className="col-md-6 col-sm-12 my-1">
                                                          <div className="form-group">
                                                              <label htmlFor="username">Name</label>
                                                              <input 
                                                              type="text"
                                                              className="form-control ng-untouched ng-pristine ng-valid" 
                                                              {...register('fullName', { required: true })} 
                                                               value={fullName} 
                                                               onChange ={(e) => setFullName(e.target.value)}
                                                              />
                                                          </div>
                                                      </div>
                                                      <div className="col-md-6 col-sm-12 my-1">
                                                          <div className="form-group">
                                                              <label htmlFor="email_address">Email Address</label>
                                                              <input
                                                                  type="text"
                                                                  className="form-control ng-untouched ng-pristine ng-valid"
                                                                  readOnly = {checkExistEmail} 
                                                                  {...register('emailAddress', { required: true, type: 'email' })} 
                                                                      value={emailAddress} 
                                                                      onChange ={(e) => setEmailAddress(e.target.value)}
                                                              />
                                                               {errors.emailAddress && <p className="text-center text-danger help-block"></p>}
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="row">
                                                      <div className="col-md-6 col-sm-12 my-1">
                                                          <div className="form-group">
                                                              <label htmlFor="phone_no">Phone number</label>
                                                              <input
                                                                  type="tel"
                                                                //   pattern="[0-9]{10}"
                                                                  className="form-control ng-untouched ng-pristine"
                                                                  readOnly ={checkExistPhone} 
                                                                  {...register('phoneNo', { required: true })} 
                                                                      value={phoneNo} 
                                                                      onChange ={(e) => setPhoneNo(e.target.value)}
                                                              />
                                                               {/* {errors.phoneNo && <p className="text-center text-danger help-block">valid phone is required </p>} */}

                                                          </div>
                                                      </div>
                                                      <div className="col-md-6 col-sm-12 my-1">
                                                          <div className="form-group">
                                                              <label htmlFor="country"> Country </label>
                                                              <select formcontrolname="country" name="country" required={true} disabled readOnly className="form-control ng-untouched ng-pristine ng-valid">
                                                                  <option value="Uganda"> Uganda </option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="row">
                                                      <div className="col-md-12 col-sm-12 my-1">
                                                          <div className="form-group">
                                                              <label htmlFor="phone_no">Address</label>
                                                              <textarea
                                                                  type="text"
                                                                  className="form-control ng-untouched ng-pristine ng-valid"
                                                                  {...register('Address', { required: true })} 
                                                                      value={Address} 
                                                                      onChange ={(e) => setAddress(e.target.value)}
                                                              ></textarea>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <button type="submit" className="btn btn-success my-1">
                                                      <span className="text-light">SAVE</span>
                                                  </button>
                                              </form>
                                          </div>
                                      </div>
                                  </div>
                                  
                              </div>
                          </div>
                          
                      </div>
                  </div>
              </div>
        );
}
 
export default AccountProfile;
import { Link } from "react-router-dom";
import SubMinus from '../assets/media/minus.svg';
import AddPlus from '../assets/media/plus.svg';
const ProductCat = ({ products, currency, onAdd, cartItems, altImage, onRemove }) => {
       return ( 
              <>
              { products?.map((product)=>(
              <div  className="col mb-4 pl-3"  key={product.id}>
                     <div  className="card h-100" tabIndex="0">
                            <div>
                          <div  className="product-item grid">
                              <Link to={`/product/${btoa(product.id)}`} className="prod categories-preview">
                                  <img  height="150" className="img-file" loading="lazy" src={product.image} alt={product.meta_data} />
                              </Link>
                              <div  className="card-body">
                                  <Link to={`/product/${btoa(product.id)}`}  className="justify-content-start d-flex price fw-bold categories-preview">{currency} {product.price}</Link>
                                  
                                  <div  className="promotion m-0 p-0"></div>
                                  <Link to={`/product/${btoa(product.id)}`}  className="categories-preview card-title mb-0">
                                      <div >
                                          {product.name}
                                      </div>
                                      
                                  </Link >
                                  
                                  <div  className="rating d-flex pt-1-">
                                      <Link to={`/product/${btoa(product.id)}`} className="categories-preview pe-2" >
                                          <div  className="stars">
                                              <span  className="fa fa-star checked"></span><span  className="fa fa-star checked"></span>
                                              <span  className="fa fa-star checked"></span><span  className="fa fa-star checked"></span>
                                              <span  className="fa fa-star checked"></span>
                                              
                                          </div>
                                      </Link>
                                      
                                  </div>
                                  <div  >
                                  <div  className="justify-content-center">
                                        {!cartItems.some(item => item.id === product.id) &&
                                          <div  className="py-1 action-btn">
                                              <button onClick={() => onAdd(product)} className="w-100 btn-block btn btn-success text-white btn-add-to-cart">
                                                  <i  className="fa fa-shopping-cart"></i> Add to Cart
                                              </button>
                                              
                                          </div>
                                        }
                                        {cartItems.some(item => item.id === product.id) &&
                                        <div className="p-1 action-btn">
                                            <div>
                                           
                                                <div >
                                                    <div  className="input-group">
                                                        <span  className="input-group-btn">
                                                            <button  onClick={() => onRemove(product)} className="btn-block quantity-left-minus btn btn-success btn-number">
                                                                <img  src={SubMinus} alt={altImage} loading="lazy" />
                                                            </button>
                                                        </span>
                                                        {
                                                        cartItems.map((item) => (
                                                        item.id === product.id &&
                                                        <p key={item.id} className="pl-5 border-0 text-center form-control input-number ng-untouched ng-pristine ng-valid">  
                                                        {item.qty}
                                                        </p>
                                                        ))}
                                                        <span  className="input-group-btn">
                                                            <button  type="button" onClick={() => onAdd(product)} className="btn-block quantity-right-plus btn btn-success btn-number">
                                                                <img  src={AddPlus} alt={altImage} loading="lazy" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
}
                                          <div  className="p-1 action-btn d-none"></div>
                                      </div>
                                  </div>
                                  
                              </div>
                          </div>                          
                      </div>
                  </div>
              </div>
               ))}
       </>
        );
}
 
export default ProductCat;

import { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import MainLogo from '../assets/media/logo.png';
import NavUser from '../assets/media/user.svg';
import NavLogout from '../assets/media/logout.svg';
import NavCart from '../assets/media/shopping-cart-alt.svg';
import NavChevron from '../assets/media/chevron_right.svg';
import NavPhone from '../assets/media/phone.svg';
import LoginModal from "./LoginModal";
import MobileSidebar from './mobileSidebar';
// import AuthContext from "./../context/userContext";
import LogoutModal from "./LogoutModal";
import axios from "axios";
import { GoogleOAuthProvider } from '@react-oauth/google';
const Navbar = ({base_url, cartItems}) => {
        const [openModal, setOpenModal] = useState(false);
        const [openModalLogout, setOpenModalLogout] = useState(false);
        const [openModalMobile, setOpenModalMobile] = useState(false);
        const [profileName, setProfileName] = useState('');
        const navbarLogin = localStorage.getItem('accessToken') !== null;
        // const { auth } = useContext(AuthContext);
        const history = useHistory();
        const altImage = 'Agrikatale Alt Image Not Found'; 
        if (navbarLogin){
             axios({
                method: 'get',
                url: base_url+'shop/users',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem('accessToken')
                }
              })
              .then(response => {
                    // console.log(response.data.data.user[0].name);
                    setProfileName('');
                    setProfileName(response.data.data.user[0].name);
              })
              .catch(error => {
                    // console.log(error.response.status);
                    if (error.response.status === 401){
                        setProfileName('');
                        localStorage.clear();
                        history.push("/");
                    }
                    
              })
        }
       return ( 
           <>
              <nav  id="navbar" className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
                  <div  className="container">
                      <Link  to="/" className="navbar-brand"><img  src={MainLogo} height="30" alt={altImage} loading="lazy" /></Link>
                      <div  className="ms-auto row- d-lg-none">
                          <div  className="col-auto">
                          { !navbarLogin &&
                              <button  className="btn btn-link nav-link" onClick={() => {setOpenModal(true)}}>
                                  <img  src={NavUser} alt={altImage} loading="lazy" />
                              </button>
                            }
                            { navbarLogin && 
                             <button  className="btn btn-link nav-link"  onClick={() => {setOpenModalLogout(true)}}>
                            
                             <img  src={NavLogout} height="26" alt={altImage} />
                            </button>
                            }
                              <Link to="/cart" className="nav-link">
                                  <img  src={NavCart} alt={altImage} loading="lazy" />
                                  { cartItems.length !== 0 &&
                                    <span className="position-absolute top-2 start-80 translate-middle badge rounded-pill bg-orange-dark"> {cartItems.length} </span>
                                    }
                              </Link>
                              <button  data-bs-toggle="modal" data-bs-target="#mobile-menu" className="navbar-toggler fa fa-bars" onClick={() => {setOpenModalMobile(true)}}></button>
                          </div>
                      </div>
                      <div  className="navbar-collapse justify-content-center">
                              <div  id="seachBar" className="px-2">
                                  <div  className="row m-0">
                                      <div  className="col pe-lg-2 px-0">
                                          <input
                                              
                                              type="search"
                                              placeholder="Search"
                                              aria-label="Search"
                                              id="searchbar"
                                              aria-describedby="search-addon"
                                              className="form-control rounded ng-untouched ng-pristine ng-valid"
                                          />
                                      </div>
                                      <div  className="col-auto px-0">
                                          <p className="d-none"></p>
                                          <button
                                              id="search-addon"
                                              type="submit"
                                              routerlink="/catalog"
                                              queryparamshandling="merge"
                                              routerlinkactive="text-muted"
                                              className="btn btn-success shadow- fw-bold text-white"
                                              tabIndex="0"
                                          >
                                              Search
                                          </button>
                                      </div>
                                  </div>
                              </div>
                      </div>
                      <div  id="sidebar" className="collapse navbar-collapse justify-content-end">
                          <div  className="d-none d-lg-block h-mobile">
                              <ul  className="navbar-nav ms-auto mb-2 mb-lg-0">
                                { !navbarLogin &&
                                  <li  className="nav-item me-3 me-lg-0">

                                        <button className="btn btn-link nav-link" onClick={() => {setOpenModal(true)}}>
                                          <img  src={NavUser} alt={altImage} loading="lazy" /> Signin
                                          <img  className="rotate-90-degree" src={NavChevron} alt={altImage} />
                                      </button>
                                        </li>
                                    }
                                    { navbarLogin &&
                                            
                                        <div  className="dropdown">
                                        <button  className="nav-link btn btn-link">
                                    <img  src={NavUser} alt={altImage} loading="lazy" /><span >&nbsp;Hi, </span> {!profileName && <span className="text-sm">Customer</span>} {profileName && profileName} 
                                        </button>
                                        <div  className="dropdown-menu">
                                        <Link to="/profile"  className="dropdown-item btn btn-link"> Profile </Link>
                                        <Link to="/orders"  className="dropdown-item btn btn-link"> Orders </Link>
                                        <button onClick={() => {setOpenModalLogout(true)}}  className="dropdown-item btn btn-link"> Logout </button>
                                        </div>
                                        </div>
                                    }                     
                                
                                  <li  className="nav-item me-3 me-lg-0 dropdown">
                                      <button  data-toggle="dropdown"  className="btn btn-link nav-link"><img  src={NavPhone} alt={altImage} loading="lazy" /> Help </button>
                                      <ul  className="dropdown-menu">
                                          <div  className="contact">
                                              <span ><i  className="fa fa-phone"></i> Call</span><a  href="tel:+256773060208"> +256 773 060 208 </a>
                                              <span ><i  className="fab fa-whatsapp"></i> Whatsapp</span><a  href="tel:+256773060208"> +256 773 060 208 </a>
                                          </div>
                                      </ul>
                                  </li>
                                  <li  className="nav-item me-3 me-lg-0">
                                      <Link  to="/cart" routerlinkactive="active" className="nav-link">
                                          <img  src={NavCart} alt={altImage} loading="lazy" /> Cart
                                          { cartItems.length !== 0 &&
                                          <span className="position-absolute top-2 start-80 translate-middle badge rounded-pill bg-orange-dark"> {cartItems.length} </span>
                                          }
                                          </Link>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </nav>
              
              <GoogleOAuthProvider clientId="74016251457-0esgclqv6rjmc44obd948kcul9egpuq8.apps.googleusercontent.com">
              {openModal && <LoginModal  altImage={altImage} closeModal={setOpenModal} base_url = { base_url } />}
              {openModalLogout && <LogoutModal  altImage={altImage} closeModalLogout={setOpenModalLogout} base_url = { base_url } />}
              </GoogleOAuthProvider>
              
              {openModalMobile && <MobileSidebar base_url={base_url} navbarLogin={ navbarLogin } altImage={altImage} modalLogin = { setOpenModal } closeModalMobile={setOpenModalMobile} />}
                  
            </>
        );
}
 
export default Navbar;
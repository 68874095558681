import { Link } from "react-router-dom";
import { useState } from "react";
const CartSide = ({base_url,
       altImage,
       cartItems,
       navbarLogin,
       currency,
       itemsPrice,
       LoginModal,
       GoogleOAuthProvider,
       checkOut
       }) => {
              const [openModal, setOpenModal] = useState(false);
       return ( 
              <div  className="col-md-3 summary">
              { cartItems.length === 0 &&
                  <div >
                      <div  className="cart-box">
                          <div  className="cart-box-title p-md-1">
                              <h6  className="subtitle">
                                  Shopping cart
                                  
                              </h6>
                          </div>
                          <div  className="cart-box-content p-md-1">
                              <div  className="d-flex justify-content-between"><div className="text-sm" >Your cart items will appear here</div></div>
                              <hr  />
                              
                          </div>
                      </div>
                  </div>
              }   
          { cartItems.length !== 0 &&
                  <div  >
                      <div  className="cart-box">
                          <div  className="cart-box-title p-md-1">
                              <h6  className="subtitle">
                                  Shopping cart ({cartItems.length} items)
                                  
                              </h6>
                          </div>
                          <div  className="cart-box-content p-md-1">
                              <div  className="d-flex justify-content-between">
                                  <div >Total:</div>
                                  <div  className="fw-bold">{currency} {itemsPrice.toLocaleString('en-US')}</div>
                              </div>
                              <span  className="text-muted small"> N/B: delivery fee not included </span>
                              <hr  />
                              <div  className="m-t-sm">
                              <GoogleOAuthProvider clientId="74016251457-0esgclqv6rjmc44obd948kcul9egpuq8.apps.googleusercontent.com">
                               {openModal && <LoginModal  altImage={altImage} closeModal={setOpenModal} base_url = { base_url } />}
                                  </GoogleOAuthProvider>
                                  {!navbarLogin &&
                                  <div>
                                      <button onClick={() => setOpenModal(true) } className="text-nowrap btn btn-primary btn-green text-white btn-block w-100" tabIndex="0">Complete Order ({cartItems.length})</button>
                                  </div>
                                  }
                                  {navbarLogin &&
                                   <div>
                                          {!checkOut &&
                                   <div>
                                      <Link to="/checkout" className="text-nowrap btn btn-primary btn-green text-white btn-block w-100" tabIndex="0">Complete Order ({cartItems.length})</Link>
                                  </div>
                                   }  
                                   
                                    {checkOut &&
                                          <div>
                                             <Link to="/cart" className="text-nowrap btn btn-primary btn-green text-white btn-block w-100" tabIndex="0">View Cart ({cartItems.length})</Link>
                                         </div>
                                          }
                                  </div>
                                  }
                                  
                                  <div  className="m-t-sm">
                                      <div  className="pt-2">
                                          <div  className="pt-2">
                                              <Link to="/catalog"  className="text-nowrap w-100 btn-block btn btn-outline-success" tabIndex="0">Continue Shopping</Link>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  }
              </div>
        );
}
 
export default CartSide;
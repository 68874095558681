import EmptyCart from '../assets/styles/empty_cart.svg';
import ReturnIcon from '../assets/media/return.svg';
import BinDelete from '../assets/media/bin.svg';
import SubMinus from '../assets/media/minus.svg';
import AddPlus from '../assets/media/plus.svg';
import { Link } from 'react-router-dom';
import LoginModal from "./LoginModal";
import { GoogleOAuthProvider } from '@react-oauth/google';
import CartSide from './CartSide';

const Cart = ({altImage, cartItems, onAdd, currency, onRemove, onDelete, base_url}) => {
    const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price.replace(/,/g,''), 0);
    const navbarLogin = localStorage.getItem('accessToken') !== null;
       return ( 
           <div>
                    <section  className="container-fluid">
                        <div  className="container">
                            <div  className="wrapper wrapper-content animated fadeInRight">
                                <div  className="row">
                                    <div  className="col d-none d-md-block">
                                        <div  className="pb-2">
                                            <b >     
                                            </b>
                                        </div>
                                    </div>
                                    <div  className="col d-md-none">
                                        <Link  className="p-2 categories-preview" to='/catalog'>
                                            <img  src={ReturnIcon} role="banner" loading='lazy' className="pe-2  cart-icon-back" alt={altImage} />
                                            <b >
                                                My Cart
                                                
                                            </b>
                                        </Link>
                                    </div>
                                </div>
                                <div  className="row">
                                    <div  className="col-md-9 cart">
                                        <div>
                                            <div  className="card">
                                                <div  className="card-header">
                                                    Cart
                                                </div>
                                                { cartItems.length === 0 && 
                                                <div  className="card-body text-center">
                                                    <div  className="row icon">
                                                        <img  src={EmptyCart} className="banner-shopping-cart" alt={altImage} />
                                                        
                                                    </div>
                                                    <div  className="row justify-content-center">
                                                        <div  className="col-12 col-lg-6">
                                                            <div  className="pt-4">
                                                                <p  className="pt-3">You don’t have any Items in your cart</p>
                                                                <Link  to="/catalog" role="button" className="text-primary py-2" tabIndex="0"><u >Start Shopping</u></Link>
                                                                
                                                            </div>
                                                            <div  className="sub-text fw-bold-200">
                                                                All items in the cart will appear here
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                        { cartItems.map((item) => (
                                            <div  className="cart-box-content" key={item.id}>
                                                <div  className="table-responsive">
                                                    <table  className="table shoping-cart-table">
                                                        <tbody >
                                                            <tr>
                                                                <td  className="td-fit-width">
                                                                    <div  className="product-img p-0">
                                                                        <img  className="img-fluid cart" src={item.image} alt={altImage} />
                                                                    </div>
                                                                </td>
                                                                <td  className="desc">
                                                                    <div  className="d-flex justify-content-between">
                                                                        <h3  className="float-start m-0" tabIndex="0">
                                                                         {item.name}
                                                                        </h3>
                                                                        <h3  className="float-end d-md-block d-none">{currency} {(item.price.replace(/,/g,'') * item.qty).toLocaleString('en-US')}</h3>
                                                                    </div>
                                                                    <div  className="text-muted supplier">{item.supplier.name}</div>
                                                                    <div  className="text-muted pb-1">
                                                                        <div  className="d-flex justify-content-between">
                                                                            <div >{item.unit} | {currency} {item.price} Per</div>
                                                                            <div  className="ms-auto d-none d-md-block">
                                                                                <div >
                                                                                    <div >
                                                                                        <div  className="input-group">
                                                                                            <span  className="input-group-btn">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={() => onRemove(item)}
                                                                                                    className="btn-block quantity-left-minus btn btn-success btn-number"
                                                                                                >
                                                                                                    <img  src={SubMinus} loading="lazy" alt={altImage} />
                                                                                                </button>
                                                                                            </span> 
                                                                                            <p className="px-5 border-0 text-center form-control input-number ng-untouched ng-pristine ng-valid">  
                                                                                            {item.qty}
                                                                                            </p>
                                                                                            <span  className="input-group-btn">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={() => onAdd(item)}
                                                                                                    className="btn-block quantity-right-plus btn btn-success btn-number"
                                                                                                >
                                                                                                    <img  src={AddPlus} alt={altImage} loading="lazy" />
                                                                                                </button>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div  className="pb-1 d-md-none"><h3  className="fw-bold text-success">{currency} {(item.price.replace(/,/g,'') * item.qty).toLocaleString('en-US')}</h3></div>
                                                                    <div  className="d-md-block d-none">
                                                                        <div  className="d-flex">
                                                                            <div  role="button" onClick={() => onDelete(item)} className="text-nowrap m-t-sm text-danger border-end me-2 pe-2 border-2">
                                                                                <img  src={BinDelete} alt={altImage} /><span > Delete </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                                
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div  className="d-md-none border-top border-1">
                                                    <div >
                                                        <div  className="d-flex px-1 pt-1">
                                                            <div  role="button" onClick={() => onDelete(item)} className="text-nowrap m-t-sm text-danger border-end- me-2 pt-2 border-1- mb-1">
                                                                <img  src={BinDelete} alt={altImage} className="pb-1 ms-n1 vertical-align-middle" /><span > Delete </span>
                                                            </div>
                                                            <div  className="text-nowrap m-t-sm text-primary d-none"><img  src={item.image} alt={altImage} /></div>
                                                            <div  className="ms-auto">
                                                                <div >
                                                                    <div >
                                                                        <div  className="input-group">
                                                                            <span  className="input-group-btn">
                                                                                <button  
                                                                                type="button" 
                                                                                onClick={() => onRemove(item)} 
                                                                                className="btn-block quantity-left-minus btn btn-success btn-number"
                                                                                >
                                                                                    <img  src={SubMinus} alt={altImage} loading="lazy" />
                                                                                </button>
                                                                            </span>
                                                                            <p className="px-5 border-0 text-center form-control input-number ng-untouched ng-pristine ng-valid">  
                                                                                            {item.qty}
                                                                                            </p>
                                                                            <span  className="input-group-btn">
                                                                                <button  
                                                                                type="button" 
                                                                                onClick={() => onAdd(item)} 
                                                                                className="btn-block quantity-right-plus btn btn-success btn-number"
                                                                                >
                                                                                    <img  src={AddPlus} alt={altImage} loading="lazy" />
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                            </div>
                                        </div>
                                        
                                    </div>
                                   <CartSide  base_url={base_url} altImage={altImage} cartItems={cartItems} navbarLogin = {navbarLogin} currency={currency} itemsPrice={itemsPrice} LoginModal={LoginModal} GoogleOAuthProvider={GoogleOAuthProvider} />
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                    
                </div>
        );
}
 
export default Cart;
import useFetch from "./useFetch";
import NewArrivals from './NewArrivals';
import CategoriesHome from './CategoriesHome';
import AppSideBar from './AppSideBar';
import { Link } from 'react-router-dom';
const AppContent = ({base_url, altImage, banner_url, currency, ProductChevron, banner_url_two, banner_url_one}) => { 
   
       const { error, isPending, data: newdata  } = useFetch(base_url+'shop/categories');
       const categories = newdata?.data?.categories;
       const { error_products, isPending_products, data: newData  } = useFetch(base_url+'shop/products');
       const products =  newData?.data?.products;
    return ( 
           <div id="app-content" className="pt-3 pb-3">
            { error && <div>{ error }</div> }
              { isPending && <div>Loading...</div> }
                  <div className="container pb-3">
                      <div className="row promotion">
                        <AppSideBar ProductChevron = { ProductChevron } altImage={ altImage } categories = { categories } />
                          <div className="col-lg col-12 pl-0">
                                  <div  className="d-block">
                                      <div  id="indicators" data-bs-ride="carousel" className="carousel slide">
                                          <div  className="carousel-indicators">
                                              <button  type="button" data-bs-target="#indicators" aria-current="true" aria-label="Slide 1" className="active" data-bs-slide-to="0"></button>
                                          </div>
                                          <div  className="carousel-inner">
                                              <div  className="carousel-item active">
                                                  <div  className="picsum-img-wrapper"><img  className="d-block w-100" loading='lazy' src={banner_url} alt="" /></div>
                                              </div>
                                          </div>
                                          
                                          <button  type="button" data-bs-target="#indicators" data-bs-slide="prev" className="carousel-control-prev">
                                              <span  aria-hidden="true" className="carousel-control-prev-icon"></span><span  className="visually-hidden">Previous</span>
                                          </button>
                                          <button  type="button" data-bs-target="#indicators" data-bs-slide="next" className="carousel-control-next">
                                              <span  aria-hidden="true" className="carousel-control-next-icon"></span><span  className="visually-hidden">Next</span>
                                          </button>
                                      </div>
                                  </div>
                              
                          </div>
                          <div className="col-md-3 d-md-none">
                                  <div  className="my-2">
                                      <div  className="content-">
                                          <div  className="row row-cols-3 row-cols-sm-3 mx-0">
                                            {categories?.map((category)=>(
                                              <Link  to={`/catalog/${category.name}`} className="col p-1 categories-preview" tabIndex="0" key={category.id}>
                                                  <div  className="card h-80">
                                                      <div>
                                                          <div  className="card-img" >
                                                          <img  className="h-10" src={category.icon} alt={altImage} />
                                                          </div>
                                                          <div  className="card-body">
                                                              <div  className="card-title">
                                                                  {category.name}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </Link>
                                              ))}
                                          </div>
                                      </div>
                                  </div>
                          </div>
                      </div>
                  </div>
                  <div className="container fluid-mobile">
                    <div id="accordionExample" className="accordion"></div>
                  </div>
                  <NewArrivals base_url = {base_url} banner_url_one = {banner_url_one} banner_url_two = {banner_url_two} currency ={currency} title={"All Products"} />
                  <div className="container fluid-mobile">
                      {products && <CategoriesHome products={products.filter((product) => product.category.name === 'Livestock')} title="Livestock" currency ={currency} matches ={isPending_products} render ={error_products} />}
                      {products && <CategoriesHome products={products.filter((product) => product.category.name === 'Fertilizers')} title="Fertilizers" currency ={currency} matches ={isPending_products} render ={error_products} />}
                      {products && <CategoriesHome products={products.filter((product) => product.category.name === 'Seeds')} title="Seeds" currency ={currency} matches ={isPending_products} render ={error_products} />}
                      {products && <CategoriesHome products={products.filter((product) => product.category.name === 'Pesticides')} title="Pesticides" currency ={currency} matches ={isPending_products} render ={error_products} />}
                      {products && <CategoriesHome products={products.filter((product) => product.category.name === 'Processed Products')} title="Processed Products" currency ={currency} matches ={isPending_products} render ={error_products} />}
                      {products && <CategoriesHome products={products.filter((product) => product.category.name === 'Fresh Produce')} title="Fresh Produce" currency ={currency} matches ={isPending_products} render ={error_products} />}
                      {products && <CategoriesHome products={products.filter((product) => product.category.name === 'Dry Produce')} title="Dry Produce" currency ={currency} matches ={isPending_products} render ={error_products} />}
                  </div>              
          </div>
        );
}
 
export default AppContent;
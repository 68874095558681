// import { useState } from 'react';
import { Link } from 'react-router-dom';
import NavUser from '../assets/media/user.svg';
import OrdersIcon from '../assets/media/orders.svg';
// import axios from 'axios';
// import { Player, Controls } from '@lottiefiles/react-lottie-player';

const OrderSummary = ({ altImage, base_url }) => {

    return ( 
        <div className="container mt-3">
        <div  className="wrapper wrapper-content">
            {/* <div  className="row header"></div> */}
            <div  className="body">
                <div  className="row m-0 justify-content-center">
                <div  className="col-md-4 d-none d-md-block">
                                  <ul  className="list-unstyled bg-white nav-items">
                                      <li  className="border-top my-3-"></li>
                                          <Link  to="/profile" className="categories-preview"> 
                                                 <li   className="m-1  px-5 py-2 topnav-item text-nowrap fw-bold">
                                                        <img  src={NavUser} alt={ altImage } /> Account
                                                 </li>
                                          </Link>
                                      <li  className="border-top my-3-"></li>
                                      <Link  to="/orders" className="categories-preview active-nav-account-sidebar"> 
                                      <li  className="mb-1  px-5 py-2 topnav-item text-nowrap fw-bold ">
                                          <img  src={OrdersIcon} alt={ altImage } /> Orders
                                      </li>
                                   </Link>

                                      <li  className="border-top my-3-"></li>
                                      <Link  to="/" className="categories-preview"> 
                                      <li  className="mb-3- text-center">
                                          <button  className="btn btn-toggle align-items-center fw-bold rounded my-2 collapsed text-uppercase">Home</button>
                                      </li>
                                   </Link>
                                      
                                  </ul>
                              </div>
                    <div  className="col-sm-12 col-md-8 col-lg-8">
                    <div>
                                      <div className="card panel">
                                          <div className="card-body">
                                          <div className="spinner-border text-success" role="status">
                                            <span className="sr-only text-lg text-success">Loading...</span>
                                        </div> 
                                        <p className="p-5">Thank You for Purchasing With Agrikatale, Please Wait Delivery To Be First Made </p>
                      </div>
                  </div>
                </div>
                   </div>
            </div>
        </div>
    </div>
</div>
     );
}
 
export default OrderSummary;

// export const msalConfig = {
//        auth: {
//          clientId: "c5d6be6d-9800-4e2f-a967-0ce75b3bc2e2",
//          authority: "https://login.microsoftonline.com/add9154d-a58b-4dd1-a094-51e7d5f8a1d5", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//          redirectUri: "http://localhost:3000",
//        },
//       scopes: ["User.Read"],
//        cache: {
//          cacheLocation: "sessionStorage", // This configures where your cache will be stored
//          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//        }
//      };
     
//      // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
//      export const loginRequest = {
//       scopes: ["User.Read"]
//      };
     
//      // Add the endpoints here for Microsoft Graph API services you'd like to use.
//        //      export const graphConfig = {
//        //          graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
//        //      };

       
// import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: "c5d6be6d-9800-4e2f-a967-0ce75b3bc2e2",
        authority: "https://login.microsoftonline.com/add9154d-a58b-4dd1-a094-51e7d5f8a1d5",
        redirectUri: "http://localhost:3000"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
              //   switch (level) {		
              //       case LogLevel.Error:		
              //           console.error(message);		
              //           return;		
              //       case LogLevel.Info:		
              //           console.info(message);		
              //           return;		
              //       case LogLevel.Verbose:		
              //           console.debug(message);		
              //           return;		
              //       case LogLevel.Warning:		
              //           console.warn(message);		
              //           return;	
              //        default:
              //        console.log(message);
              //        return;	
              //   }	
            }	
        }	
    }
};


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 export const loginRequest = {
       scopes: ["User.Read"]
   };
   
   /**
    * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
    */
   export const graphConfig = {
       graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
   };
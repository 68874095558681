import { useState, useEffect } from 'react';

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState([]);

  useEffect(() => {
    const abortCont = new AbortController();
      fetch(url, { signal: abortCont.signal })
      .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        } 
        return res.json();
      })
      .then(data => {
        setIsPending(false);
        setData(data);
        setError([]);
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('fetch abort error')
        } else{
        // auto catches network / connection error
        setIsPending(false);
        setError(err.message);
        }
      })
      return () => abortCont.abort();
  }, [url])

  return { data, isPending, error };
}
 
export default useFetch;
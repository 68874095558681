
import useFetch from "./useFetch";
import { Link } from "react-router-dom";
const NewArrivals = ({base_url, banner_url_two, banner_url_one, currency, title}) => {
        const { data: newData  } = useFetch(base_url+'shop/products');
        const products =  newData?.data?.products;
       
       return ( 
        <div className="container fluid-mobile">
        <div  className="product-panel">
            <div  className="section">
                    <div  className="section">
                        <div  className="header p-2 border-bottom">
                            <div  className="row align-aitem-center w-100 mx-0">
                                <div  className="col-6 col-lg-9">
                                    
                                    <h1 className="text-dark">{title}</h1>
                                    
                                    <p  className="timer mb-0 ms-lg-4 text-white"></p>
                                    
                                </div>
                                <div  className="col-lg-3 col-6 pe-0 text-end">
                                    <Link to="/catalog" className="view-more float-right btn-sm btn btn-success">VIEW MORE</Link>
                                    </div>
                            </div>
                        </div>
                    </div>
        <div  role="button" className="products row row-cols-2 row-cols-md-4 row-cols-lg-5 mt-2">
        { products?.slice(0, 5).map((product)=>(
                <Link to={`/product/${btoa(product.id)}`}  className="col categories-preview" tabIndex="0" key={product.id}>
                <div  className="card border-0 rounded h-100">                                                      
                        <div className="product-item grid">
                    <div className="prod">
                        <img height="165" className="img-file" src={product.image} alt={product.description} />
                        
                    </div>
                    
                    <div className="card-body">
                        <p className="justify-content-center- d-flex- price fw-bold">{currency} {product.price}</p>
                        
                        <div className="promotion m-0 p-0"></div>
                        <div className="card-title mb-0">
                            <div>
                                <span> {product.name} </span>
                            </div>
                            
                        </div>
                        
                        <div className="rating d-flex pt-1-">
                                <div  className="stars">
                                    <span  className="fa fa-star checked"></span><span  className="fa fa-star checked"></span>
                                    <span  className="fa fa-star checked"></span><span  className="fa fa-star checked"></span>
                                    <span  className="fa fa-star checked"></span>
                                    
                                </div>
                            
                        </div>
                        
                    </div>
                </div>
                
        </div>
        
    </Link>
     ))}
        </div>                    
       </div>
    </div>
        
    <div className="pt-2- row">
        <div className="col-lg col-md col-12 pe-lg-2">
            <div className="banner-holder card p-1 border-0"><img className="img-fluid" src={banner_url_one} alt="Agriculture promotion" loading="lazy" /></div>
        </div>
        <div className="col-lg col-md col-12 ps-lg-2">
            <div className="banner-holder card p-1 border-0"><img className="img-fluid" src={banner_url_two} alt="Livestock promotion" loading="lazy" /></div>
        </div>
    </div>
    
</div>
        
        );
}
 
export default NewArrivals;

import React, {useState, useContext} from 'react';
import MainLogo from '../assets/media/logo192.png';
import Recaptcha from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from "./../context/userContext";
import { useGoogleLogin  } from '@react-oauth/google';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { callMsGraph } from "../graph";
import { useHistory } from 'react-router-dom';
const LoginModal = ({closeModal, altImage, base_url}) => {
    const { setAuth } = useContext(AuthContext);
    const [isPending, setIsPending] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [pincode, setPincode] = useState('');
    const [username, setUsername] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm(); 
      const history = useHistory();
      const onSubmit = (postdata) => {
        setIsPending(true);
        axios({
            method: 'post',
            url: base_url+'shop/loginToken',
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(postdata)
          })
          .then(response => {
                // console.log(response.data);
                localStorage.setItem("token", response.data.data.token);
                setIsPending(false);
                setIsLogin(true);
                
          })
          .catch(error => {
                // console.log(error.response.data.messages);
                setIsPending(false);
                toast.error(error.response.data.messages[0]);
          })
    };

    const onSubmitTwo = (otpdata) =>{
        // console.log(otpdata)
        setIsPending(true);
        axios({
            method: 'post',
            url: base_url+'shop/sessions',
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("token")
            },
            data: JSON.stringify(otpdata)
          })
          .then(response => {
                // console.log(response.data);
                const accessToken = response.data.data.access_token;
                const refreshToken = response.data.data.refresh_token;
                setAuth({accessToken, refreshToken});
                // console.log(setAuth);
                localStorage.setItem("sessionID", response.data.data.session_id);
                localStorage.setItem("accessToken", response.data.data.access_token);
                localStorage.setItem("refreshToken", response.data.data.refresh_token);
                setIsPending(false);
                closeModal(false)
                history.push(0);

          })
          .catch(error => {
                // console.log(error.response.data.messages);
                setIsPending(false);
                for (var i = 0; i < error.response.data.messages.length; i++) {
                    toast.error(error.response.data.messages[i]);
                } 
          })
    }
    const createLocalAccount = async(names, email) => {
                const data = {
                    names: names,
                    email: email
                }
                // console.log(data);
          await axios({
                method: 'post',
                url: base_url+'shop/google-authentication',
                headers: {
                    "Content-Type": "application/json",
                },
            data: JSON.stringify(data)
                }).then(response => {
                    // console.log(response)
                localStorage.setItem("sessionID", response.data.data.session_id);
                localStorage.setItem("accessToken", response.data.data.access_token);
                localStorage.setItem("refreshToken", response.data.data.refresh_token);
                setIsPending(false);
                closeModal(false);
                history.push(0);

                }).catch(error => {
                    for (var i = 0; i < error.response.data.messages.length; i++) {
                        toast.error(error.response.data.messages[i]);
                    } 
                })
    }
    const login_google = useGoogleLogin({
        onSuccess: tokenResponse => {
       
            axios.get("https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokenResponse.access_token}`
                },
            }).then(response => {
                createLocalAccount(response.data.names[0].displayName, response.data.emailAddresses[0].value);
            }).catch( error =>{
                // console.log(error);
            } )
        },
      })
      
      const { instance, accounts } = useMsal();
      const login_microsoft = () => {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.loginPopup({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            // console.log(response.accessToken)
            callMsGraph(response.accessToken).then(response => {
                // console.log(response)
                createLocalAccount(response.displayName, response.userPrincipalName);

            }
                );
        });
      }
       return ( 
<div className="modalContainer">
        <ToastContainer />
        <Recaptcha
        sitekey="6Lft-GoeAAAAAOufjALQaoHrumQ9WIePH6WER1Kj"
        size="invisible"                         
        />

<div   className="container mx-auto login-modal-dialog">
    <div  className="modal-content border-0 shadow">
        <button  type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} className="close"><span  aria-hidden="true">×</span></button>
        <div  className="items">
            <div  className="row m-0">
                <div  className="col-12 col-lg-5 left-panel p-lg-5 p-4">
                    <h5 >Sign in</h5>
                    {!isLogin &&
                    <p  className="lg">Login. <span className="help-block">Phone Number, Google or Microsoft</span> </p>
                    }
                    {isLogin &&
                    <p  className="lg">OTP. <span className="help-block">Enter OTP received on your Mobile Phone</span> </p>
                    }
                    <img  src={MainLogo} height="80" className="illustration h-mobile" alt={altImage} />
                </div>
               {!isLogin && <form onSubmit={handleSubmit(onSubmit)}  className="col-12 col-lg-7 p-lg-5 p-4">
                    <h6 >Provide your mobile number to continue</h6>
                    <div  className="w-100">
                        <div className="phone-number-contract">
                        <input  
                            type="tel"  
                            placeholder="Your phone number" 
                            className="form-control  form-control-lg my-2 w-100" 
                            autoComplete="off"
                            {...register('username', { required: true, maxLength: 10, minLength: 10 })} 
                            value={username} 
                            onChange ={(e) => setUsername(e.target.value)}
                            />

                            {errors.username && <p className="text-center text-danger help-block">valid phone is required e.g. 0700000010.</p>}
                        </div>
                    </div>
                    <div >
                        <p  className="terms py-3 m-0">
                            By Continuing, you’ve agreed to Agrikatale <br />
                            <a  target="_blank" href="https://agrikatale.net/terms&conditions" rel="noreferrer" className="text-success"> Privacy Policy, Terms &amp; Conditions </a>
                        </p>
                    </div>
                    {!isPending && <button  type="submit" className="float-end w-100 btn btn-success text-white mt-1">Continue</button>}
                    {isPending && <button  type="submit" disabled className="float-end w-100 btn btn-success text-white">Loading</button>}
                    <button type="button" className="btn btn-outline-success mt-1 text-dark w-100" onClick={() => login_google()}> <i className="fab fa-google" aria-hidden="true"></i> Google 🚀{' '}</button> 
                    <button type="button" className="btn btn-outline-success mt-1 text-dark w-100" onClick={() => login_microsoft(instance)}> <i className="fab fa-windows" aria-hidden="true"></i> Microsoft</button>
                </form>
                }
                {isLogin && <form onSubmit={handleSubmit(onSubmitTwo)}  className="col-12 col-lg-7 p-lg-5 p-4">
                    <h6 >Provide your your one time password</h6>
                    <div  className="w-100">
                        <div className="phone-number-contract">
                        <input  
                            type="tel"  
                            placeholder="one time password" 
                            className="form-control form-control-lg my-2 w-100" 
                            autoComplete="off"
                            {...register('pincode', { required: true, maxLength: 5, minLength: 5 })} 
                            value={pincode} 
                            onChange ={(e) => setPincode(e.target.value)}
                            />

                            {errors.pincode && <p className="text-center text-danger help-block">please enter a valid otpCode.</p>}
                        </div>
                    </div>
                    <div className='mb-5'>
                        <button type="button"  className="btn btn-danger btn-sm float-end">
                            Resend OTP
                        </button>
                    </div>
                  
                    {!isPending && <button  type="submit" className="float-end w-100 btn btn-success text-white">Submit</button>}
                    {isPending && <button  type="submit" disabled className="float-end w-100 btn btn-success text-white">Loading</button>}
                  
                </form>}
              
            </div>
        </div>
       
    </div>
</div>
</div>


);
}
 
export default LoginModal;
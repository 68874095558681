
const Footer = () => {
       return ( 
              <>
      
              <div  className="py-4">
                  <div  className="container">
                      <div  className="row">
                          <div  className="card  card-footer col-lg col-md-6 col-sm-6 col-12 p-2 m-2">
                              <div  className="value-item pl-0 border-0">
                                  <p  className="m-0">Best agriculuture Inputs and outputs</p>
                              </div>
                          </div>
                          <div  className="card  card-footer col-lg col-md-6 col-sm-6 col-12 p-2 m-2">
                              <div  className="value-item">
                                  <p  className="m-0">Farm management made easy, Sell Online with Us</p>
                              </div>
                          </div>
                          <div  className="card  card-footer col-lg col-md-6 col-sm-6 col-12 p-2 m-2">
                              <div  className="value-item">
                                  <p  className="m-0">Easy Delivery 24/7 Service</p>
                              </div>
                          </div>
                          <div  className="card  card-footer col-lg col-md-6 col-sm-6 col-12 p-2 m-2">
                              <div  className="value-item">
                                  <p  className="m-0">Mobile money, Visa, bank payments</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
       <footer  className="page-footer font-small blue py-5">
              <div  className="container text-center text-md-left">
                  <div  className="row connect w-100- justify-content-center">
                      <div  className="col-12 col-lg-auto col-md-auto mt-md-0 mt-3">
                          <h6 >Get the mobile App</h6>
                          <a href="#/"> <i className="fab fa-google-play p-2 fa-2x"></i></a>
                          {/* <a href="#/"> <i className="fab fa-app-store-ios p-2 fa-2x"></i></a> */}
                      </div>
                      <div  className="col-12 col-lg-auto col-md-auto border-start ps-lg-5">
                          <h6  className="text-start pt-sm-3-">Connect with us</h6>
                          <div  className="social-contact ml-4 ml-sm-auto">
                              <a  href="https://www.instagram.com/agrikatale"  rel="opener"><i  className="fab fa-instagram mr-4"></i></a>
                              <a  href="https://www.facebook.com/agrikatale"  rel="opener"><i  className="fab fa-facebook-square"></i></a>
                              <a  href="https://twitter.com/agrikatale"  rel="opener"><i  className="fab fa-twitter"></i></a>
                              {/* <a  href="https://www.linkedin.com/"  rel="opener"><i  className="fab fa-linkedin"></i></a> */}
                              {/* <a  href="https://www.youtube.com/"  rel="opener"><i  className="fab fa-youtube"></i></a> */}
                          </div>
                      </div>
                  </div>
                  <div  className="h-menu pt-5">
                      <a target="_blank" rel ="noreferrer" href="https://about.agrikatale.com" >About Us </a>
                      <a target="_blank" rel ="noreferrer" href="https://extension.agrikatale.com" >Extension Services </a>
                      <a target="_blank" rel ="noreferrer" href="https://vendor.agrikatale.com" >Become A Vendor </a>
                      <a target="_blank" rel ="noreferrer" href="https://terms.agrikatale.com"> Terms and Conditions</a>
                  </div>
              </div>
              <div  className="footer-copyright text-center py-3">&copy; Agrikatale 2024. All rights reserved</div>
          </footer> 
          <div className="scroll-up fa fa-chevron-up"></div>          
          </>
          );
}
 
export default Footer;
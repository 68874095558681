import {useState, useEffect} from 'react';
import NavUser from '../assets/media/user.svg';
import NavChevron from '../assets/media/chevron_right.svg';
import OrdersIcon from '../assets/media/orders.svg';
import { Link } from 'react-router-dom';
const MobileSidebar = ({closeModalMobile, navbarLogin, altImage, base_url, modalLogin}) => {
       const [categories, setCategories] =  useState([]);
       const [error, setError] = useState([]);
       useEffect(()=>{
            fetch(base_url+'shop/categories')
            .then(res =>
                {
                if (!res.ok) { // error coming back from server
                    throw Error('could not fetch the data for that resource');
                } 
                    return res.json();
                })
                .then(data =>{
                    setCategories(data.data.categories);
                    setError(false);
                    
                })
                .catch(err =>{
                    setError(err.message)
                })
       }, [base_url]);
       return ( 
              <div  id="mobile-menu" className="modal-mobile">
              <div  className="modal-dialog">
                  <div  className="modal-content">
                      <div  className="custom-navbar" id={error}>
                          <div  className="position-relative">
                              <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModalMobile(false)} className="close"><span className="btn btn-close p-3" aria-hidden="true"></span></button>
                              <div  className="flex-shrink-0 p-3 bg-white">
                                
                                  <ul  data-toggle="collapse" data-target="#sidebar" className="list-unstyled ps-0"> 
                                      <Link to="/" tabIndex="0"><button  className="btn btn-toggle align-items-center rounded collapsed" onClick={() => closeModalMobile(false)}>Home</button></Link>
                                      { navbarLogin &&
                                      <>
                                      <li  className="border-top my-3"></li>
                                      <li  role="button" aria-controls="sidebar" onClick={() => closeModalMobile(false)} routerlinkactive="active" className="mb-1 topnav-item" tabIndex="0">
                                          <Link to="/profile" className="categories-preview">
                                          <img  src={NavUser} alt={altImage} /> Account
                                          <span  className="float-right rotate-90-degree"><img  src={NavChevron} alt={altImage} /></span>
                                          </Link>
                                      </li>
                                      <li  role="button" aria-controls="sidebar" onClick={() => closeModalMobile(false)} routerlinkactive="active" className="mb-1 topnav-item" tabIndex="0">
                                      <Link to="/orders" className="categories-preview">
                                          <img  src={OrdersIcon} alt={altImage} /> My Orders
                                          <span  className="float-right rotate-90-degree"><img  src={NavChevron} alt={altImage} /></span>
                                          </Link>
                                      </li>                                            
                                      <li  className="border-top my-3"></li>
                                      </>
                                    }
                                      { !navbarLogin &&
                                    <li  role="button" aria-controls="sidebar" onClick={() => {closeModalMobile(false); modalLogin(true)}} routerlinkactive="active" className="mb-1 topnav-item" tabIndex="0">
                                    <img  src="https://img.icons8.com/wired/64/000000/login-rounded-right.png" height="26" alt={altImage} /> Sign In
                                </li>
                                    }   
                                      <li  className="mb-1">
                                          <button  className="btn btn-toggle align-items-center rounded collapsed">Categories</button>                                                      
                                              <ul  className="list-group h-100">
                                              {categories.map((category)=>(
                                                  <Link
                                                      to={`/catalog/${category.name}`}
                                                      role="button"
                                                      key={category.id}
                                                      routerlinkactive="text-muted"
                                                      queryparamshandling="merge"
                                                      data-toggle="tooltip"
                                                      data-html="true"
                                                      className="list-group-item dropend categories-preview"
                                                      tabIndex="0"
                                                      title={category.description}
                                                      onClick={() => closeModalMobile(false)}
                                                  >
                                                      <div >
                                                          <img  className="cat-icon" src={category.icon} alt={altImage} loading="lazy" />
                                                          <span  className="cat-name">{category.name}</span>
                                                          <span  className="float-right d-none d-md-block rotate-90-degree"><img  src={NavChevron} alt={altImage} /></span>
                                                      </div>
                                                  </Link>  
                                                    ))}                                                
                                              </ul>
                                      </li>
                                      <li  className="border-top my-3"></li>
                                      <li  className="mb-1"><a  href="tel:+256773060208" className="btn btn-toggle align-items-center rounded collapsed"> Help Centre (+256 773 060 208) </a></li>
                                      <li  className="border-top my-3"></li>                                      
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        );
}
 
export default MobileSidebar;
import {Link} from 'react-router-dom';

const CategoriesHome = ({products, title,currency}) => {
       return ( 
              <div className="product-panel">
                              <div  className="mt-2">
                                  <div  className="header border-bottom">
                                      <h1 >{title}</h1>
                                      <Link to={`/catalog/${title}`} className="view-more btn btn-outline-success"> View more</Link>
                                  </div>
                                      <div className="products row row-cols-2 row-cols-md-4 row-cols-lg-5 mt-2">
                                      { products?.slice(0, 5).map((product)=>(
                                          <Link to={`/product/${btoa(product.id)}`}  className="col categories-preview" tabIndex="0" key={product.id}>
                                              <div  className="card border-0 rounded h-100">
                                                      <div className="product-item grid">
                                                          <div className="prod">
                                                              <img height="165" className="img-file" src={product.image} alt={product.description} />
                                                              
                                                          </div>
                                                          
                                                          <div className="card-body">
                                                              <p className="justify-content-center- d-flex- price fw-bold">{currency} { product.price }</p>
                                                              
                                                              <div className="promotion m-0 p-0"></div>
                                                              <div className="card-title mb-0">
                                                                  <div>
                                                                      <span>{ product.name }</span>
                                                                  </div>
                                                                  
                                                              </div>
                                                              
                                                              <div className="rating d-flex pt-1-">
                                                                      <div  className="stars">
                                                                          <span  className="fa fa-star checked"></span><span  className="fa fa-star checked"></span>
                                                                          <span  className="fa fa-star checked"></span><span  className="fa fa-star checked"></span>
                                                                          <span  className="fa fa-star checked"></span>
                                                                          
                                                                      </div>
                                                              </div>
                                                          </div>
                                                      </div>      
                                              </div>
                                          </Link>
                                             ))}
                                      </div>
                              </div>
                      </div>
        );
}
 
export default CategoriesHome;

import useFetch from "./useFetch";
import { useParams } from "react-router-dom";
import SubMinus from '../assets/media/minus.svg';
import AddPlus from '../assets/media/plus.svg';
import CartSide from "./CartSide";
import LoginModal from "./LoginModal";
import { GoogleOAuthProvider } from "@react-oauth/google";
const SingleProduct = ({altImage, currency, base_url, cartItems, onAdd, onRemove}) => {
    const navbarLogin = localStorage.getItem('accessToken') !== null;
    const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price.replace(/,/g,''), 0);
       const { name } = useParams();
       const productid = atob(name);
       const { data: newdata  } = useFetch(base_url+'shop/products/'+productid);
       const products =  newdata?.data?.product;
       return ( 

    <section className="container mt-4">
        <div className="row">
            <div className="col d-none d-md-block">
               
            </div>
        </div>
        { products?.slice(0, 5).map((product)=>(
        <div className="row" key={product.id}>
            <div className="col-md-9 p-md-1 product">
                <div className="card border-none w-100">
                    
                    <div>
                        <div className="wrapper row pb-2">
                            <div className="preview col-md-5">
                                <div className="preview-pic tab-content mt-5">
                                    <div className="tab-pane prod active">
                                        <img className="img-fluid" src={product.image} loading="lazy"  alt={altImage} />
                                        
                                    </div>
                                    <div className="tab-pane prod">
                                        <img className="img-fluid" src={product.image} loading="lazy"  alt={altImage}  />
                                        
                                    </div>
                                    <div className="tab-pane prod">
                                        <img className="img-fluid" src={product.image} loading="lazy" alt={altImage}  />
                                        
                                    </div>
                                    <div className="tab-pane prod">
                                        <img className="img-fluid" src={product.image} loading="lazy" alt={altImage}  />
                                        
                                    </div>
                                    
                                </div>
                                <ul className="preview-thumbnail nav nav-tabs h-mobile">
                                    <li className="active border border-secondary border-1 p-3">
                                        <div data-toggle="tab">
                                            <img loading="lazy" className="img-fluid-tab" src={product.image} alt={altImage} />
                                        </div>
                                    </li>
                                    <li className="border border-secondary border-1 p-3">
                                        <div data-toggle="tab">
                                            <img loading="lazy" className="img-fluid-tab" src={product.image} alt={altImage} />
                                        </div>
                                    </li>
                                    <li className="border border-secondary border-1 p-3">
                                        <div data-toggle="tab">
                                            <img loading="lazy" className="img-fluid-tab" src={product.image} alt={altImage} />
                                        </div>
                                    </li>
                                    <li className="border border-secondary border-1 p-3">
                                        <div data-toggle="tab">
                                            <img loading="lazy" className="img-fluid-tab" src={product.image} alt={altImage} />
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div className="details col-md col-lg col-12 px-lg-4">
                                <h3 className="product-title mb-2">
                            {product.name}
                                </h3>
                                <div className="product-brand d-flex pb-2">
                                    <span className="text-muted pe-2 mb-auto d-md-block d-none">Supplier</span>
                                    <span className="mb-auto text-success"> {product.supplier.name} </span>
                                </div>
                                
                                <div className="rating d-flex pt-1">
                                    <div className="pe-2" >
                                        <div  className="stars">
                                            <span  className="fa fa-star checked"></span><span  className="fa fa-star checked"></span>
                                            <span  className="fa fa-star checked"></span><span  className="fa fa-star checked"></span>
                                            <span  className="fa fa-star checked"></span>
                                            
                                        </div>
                                    </div>
                                    
                                    <span className="review-no pe-2 d-sm-block d-none"> (0 rating) </span>
                                </div>
                                <div className="border-top border-bottom py-3 my-2">
                                    <div className="pricing">
                                        <p className="d-flex price text-primary- fw-bold">{currency} {product.price}</p>
                                        
                                    </div>
                                                                      
                                    <div className="action">
                                        <div>
                                            <div  className="justify-content-center">
                                                <div  className="py-1 action-btn">
                                                {!cartItems.some(item => item.id === product.id) &&
                                                    <button  className="w-100 btn-block btn btn-success text-white btn-add-to-cart" onClick={() => onAdd(product)}>
                                                        <i  className="fa fa-shopping-cart"></i> Add to Cart  
                                                    </button>
                                                } 
                                                </div>
                                                {cartItems.some(item => item.id === product.id) &&
                                        <div className="p-1 action-btn">
                                            <div>
                                           
                                                <div >
                                                    <div  className="input-group">
                                                        <span  className="input-group-btn">
                                                            <button  onClick={() => onRemove(product)} className="btn-block quantity-left-minus btn btn-success btn-number">
                                                                <img  src={SubMinus} alt={altImage} loading="lazy" />
                                                            </button>
                                                        </span>
                                                        {
                                                        cartItems.map((item) => (
                                                        item.id === product.id &&
                                                        <p key={item.id} className="pl-5 border-0 text-center form-control input-number ng-untouched ng-pristine ng-valid">  
                                                        {item.qty}
                                                        </p>
                                                        ))}
                                                        <span  className="input-group-btn">
                                                            <button  type="button" onClick={() => onAdd(product)} className="btn-block quantity-right-plus btn btn-success btn-number">
                                                                <img  src={AddPlus} alt={altImage} loading="lazy" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="price-offers">
                                  
                                    <div className="row m-0">
                                        <div className="col col-lg-auto p-0"><h3 className="product-title mb-2">Share</h3></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col col-lg-auto p-0">
                                            <button className="icon-option- btn btn-light my-2 btn-block m-1 sb-facebook" aria-label="Share on Facebook">
                                                <i className="fa-facebook fab"></i>
                                            </button>
                                            <button className="icon-option- btn btn-light my-2 btn-block m-1 sb-twitter" aria-label="Share on Twitter">
                                                <i className="fa-twitter fab"></i>
                                            </button>
                                                                                     
                                            <button className="icon-option- btn btn-light my-2 btn-block m-1 sb-whatsapp" aria-label="Share on WhatsApp">
                                                <i className="fa-whatsapp fab"></i>
                                            </button>
                                            <button className="icon-option- btn btn-light my-2 btn-block m-1 sb-email" aria-label="Share link via email">
                                                <i className="fa fa-envelope"></i>
                                            </button>
                                            
                                        </div>
                                    </div>
                                    <button data-effect="fadeOut" className="icon-option d-none"><div className="far fa-heart"></div></button>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper- pt-4">
                            <h4 className="subtitle">Product details</h4>
                            <div className="product-description"> {product.description} </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <CartSide  base_url={base_url} altImage={altImage} cartItems={cartItems} navbarLogin = {navbarLogin} currency={currency} itemsPrice={itemsPrice} LoginModal={LoginModal} GoogleOAuthProvider={GoogleOAuthProvider} />
          
        </div>
         ))}
        
    </section>

        );
}
 
export default SingleProduct;
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NavUser from '../assets/media/user.svg';
import OrdersIcon from '../assets/media/orders.svg';
import axios from 'axios';

const AccountOrders = ({altImage, base_url}) => {   
    const history = useHistory();
    const navbarLogin = localStorage.getItem('accessToken') !== null;
    const [orders, setOrders] =  useState([]);
    if (!navbarLogin){
        history.push('/');
    }           
    axios({
        method: 'get',
        url: base_url+'shop/orders',
        headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('accessToken')
        }
      })
      .then(response => {
            // console.log(response.data.data.order)
             setOrders(response?.data?.data.order);
           
      })
      .catch(error => {
            // console.log(error);
      });
      const tableStyle = {
        borderCollapse: "collapse",
        fontSize: "0.9em",
        minWidth: "400px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)"
      };
       return ( 
              <div className="container mt-3">
                  <div  className="wrapper wrapper-content">
                      {/* <div  className="row header"></div> */}
                      <div  className="body">
                          <div  className="row m-0 justify-content-center">
                              <div  className="col-md-4 d-none d-md-block">
                                  <ul  className="list-unstyled bg-white nav-items">
                                      <li  className="border-top my-3-"></li>
                                          <Link  to="/profile" className="categories-preview"> 
                                                 <li   className="m-1  px-5 py-2 topnav-item text-nowrap fw-bold">
                                                        <img  src={NavUser} alt={ altImage } /> Account
                                                 </li>
                                          </Link>
                                      <li  className="border-top my-3-"></li>
                                      <Link  to="/orders" className="categories-preview active-nav-account-sidebar"> 
                                      <li  className="mb-1  px-5 py-2 topnav-item text-nowrap fw-bold ">
                                          <img  src={OrdersIcon} alt={ altImage } /> Orders
                                      </li>
                                   </Link>

                                      <li  className="border-top my-3-"></li>
                                      <Link  to="/" className="categories-preview"> 
                                      <li  className="mb-3- text-center">
                                          <button  className="btn btn-toggle align-items-center fw-bold rounded my-2 collapsed text-uppercase">Home</button>
                                      </li>
                                   </Link>
                                      
                                  </ul>
                              </div>
                              <div  className="col-sm-12 col-md-8 col-lg-8">
                                  <div>
                                      <div className="card panel">
                                          <div className="card-body">
                                              <div className="card-title"><h4 className="text-center mt-1">Orders</h4></div>
                                              <div  className="text-center">
                                              <div className="tables mt-0">
                                              <table className="table" style={ tableStyle }>
                                                <thead>
                                                    <tr style= { { backgroundColor:" #198754",  color: "#ffffff",   textAlign:"left"} }>
                                                    <th scope="col">Order Number</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {orders?.map((order)=>(
                                                    <tr key={order.id} >
                                                    <td>{ order.order_number }</td>
                                                    { order.status === 'pending' &&
                                                        <td>Pending 😩</td>
                                                    }
                                                    { 
                                                        order.status === 'delivery' &&
                                                        <td>On Delivery 🚚</td>
                                                        }
                                                        { 
                                                        order.status === 'completed' &&
                                                        <td>Delivered 🏁</td>
                                                        }
                                                    <td>{ order.totalPrice }</td>
                                                    <td>{ order.timestamp }</td>
                                                    <td> <Link to = {`/orders/${order.id}`} className='categories-preview'><i className="text-success fa fa-eye" aria-hidden="true"></i></Link> </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                                </table>
                                              </div>
                                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
     </div>
    </div>
</div>
        );
       
}
 
export default AccountOrders;
import useFetch from "./useFetch";
import ProductCat from "./ProductsCategory";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReturnIcon from './../assets/media/return.svg';
const CategoryProducts = ({ altImage, base_url, currency, onAdd, cartItems, onRemove }) => {
       const { name } = useParams();
       const {  data: newdata  } = useFetch(base_url+'shop/categories');
       const categories = newdata?.data?.categories;
       
       const {  data: newData  } = useFetch(base_url+'shop/products');
       const products =  newData?.data?.products;
       return ( 
              <div id="app-content" className="pt-3 pb-3">
                  <div className="container pb-3">
                        <div className="col-md-12 d-md-block d-none d-sm-none">
                                  <div  className="my-2">
                                      <div  className="content-">
                                      <Link  className="p-1 categories-preview" to ="/catalog">
                                            <img  src={ReturnIcon} role="banner" loading='lazy' className="pe-2 cart-icon-back" alt={altImage} />
                                            <b >
                                              All Products
                                            </b>
                                        </Link>
                                          <div  className="row row-cols-6 row-cols-sm-6 mx-0">
                                            {categories?.map((category)=>(
                                              <Link to={`/catalog/${category.name}`} className="col p-1 categories-preview" tabIndex="0" key={category.id}>
                                                  <div  className="card">
                                                      <div>
                                                          <div  className="card-img" >
                                                          <img  className="image-height-category" loading="lazy" src={category.icon} alt={altImage} /> <br />
                                                                  {category.name}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </Link>
                                              ))}
                                          </div>
                                      </div>
                                  </div>
                          </div>
                <div className="product-panel">
                     <div className="col-lg col-12 pl-0">
                     <div  className="d-block mt-2">
                          <div  className="header border-bottom">
                                      <h2 className="fw-500 h2 text-center">{ name }</h2>
                            </div>
                            <div  className="products row mt-2 pt-2 row-cols-md-4 row-cols-lg-4 row-cols-sm-2 row-cols-2">
                            {products && <ProductCat currency = { currency } altImage={altImage} onAdd={onAdd} cartItems={cartItems} onRemove={onRemove} products = {products.filter((product) => product.category.name === name)}  />}
                                   
                            </div>
                            </div>
                          </div>
                     </div>
              </div>             
          </div>      
       
        );
}
 
export default CategoryProducts;
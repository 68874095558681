import { Link } from "react-router-dom";

const AppSideBar = ({ ProductChevron, altImage, categories }) => {
       return ( 
              <div className="col-md-3 d-none pe-0 d-md-block m-0 h-tablet">
              <div className="bg-white rounded h-100 overflow-hidden">
                      <div  className="header header-shop"><i  className="fa fa-bars me-3"></i> Category</div>
                      <ul  className="list-group h-100">
                          {categories?.map((category)=>(
                           <Link
                           to={`/catalog/${category.name}`}
                           role="button"
                           key={category.id}
                           routerlinkactive="text-muted"
                           queryparamshandling="merge"
                           data-toggle="tooltip"
                           data-html="true"
                           className="list-group-item dropend"
                           tabIndex="0"
                           title={category.description}
                       >
                           <div >
                               <img  className="cat-icon" loading="lazy" src={category.icon} alt={altImage} />
                               <span  className="cat-name">{category.name}</span>
                               <span  className="float-right d-none d-md-block"><img  src={ProductChevron} loading="lazy" alt={altImage} /></span>
                           </div>
                       </Link>   
                          ))}
                      </ul>
              </div>
          </div>
        );
}
 
export default AppSideBar;
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import './App.css';
import AppContent from './componets/AppBody';
import Navbar from './componets/Navbar';
import Footer from './componets/Footer';
import Cart from './componets/Cart';
import AllProducts from './componets/AllProducts';
import CategoryProducts from './componets/CategoryProducts';
import ProductChevron from './assets/media/chevron_right.svg';
import SingleProduct from './componets/SingleProduct';
import AccountProfile from './componets/Account';
import AccountOrders from './componets/AccountOrders';
import CheckOut from './componets/CheckOut';
import PaymentWaiting from './componets/Payment';
import OrderSummary from './componets/OrderSummary';
import { useState } from 'react';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import toast, { Toaster } from 'react-hot-toast';

function App() {
  // const base_url = 'http://192.168.43.15/development/agrikatale/';
  // const base_url = 'http://localhost/development/agrikatale/';
  const base_url = "https://api.agrikatale.com/";
  const altImage = 'Agrikatale Alt Image Not Found';
  const banner_url = 'https://agrikatale.com/demo-storage/diary-banner-min.jpg';
  const banner_url_one = 'https://agrikatale.com/demo-storage/produce.jpg';
  const banner_url_two = 'https://agrikatale.com/demo-storage/Live Stock.jpg';
  const currency = 'UGX';
  const msalInstance = new PublicClientApplication(msalConfig);
  
  const [cartItems, setCartItems] = useState([]);
  const notifyNewProduct = () => toast('New Product Added to Cart.');
  const notifyRemoveProduct = () => toast('Product Deleted.');
  const notifyUpdateProduct = () => toast('Your Cart Has Been Updated');

  const onAdd = (product) =>{
    const exist = cartItems.find(x => x.id === product.id);
    if (exist) {
        setCartItems(cartItems.map(x => x.id === product.id ? {...exist, qty: exist.qty + 1} : x));
        notifyUpdateProduct();
        
    } else {
        setCartItems([...cartItems, {...product, qty: 1}])
        notifyNewProduct();
    }
  }
  // console.log(base_url);
 
  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
      notifyRemoveProduct();
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
      notifyUpdateProduct();
    }
  }
  const onDelete = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
      notifyRemoveProduct();
    }
  }
  
  return (
    <Router>
    <div>
    <Toaster
       position="top-center"
       reverseOrder={false}
       gutter={18}
       containerClassName=""
       containerStyle={{}}
       toastOptions={{
         // Define default options
         className: '',
         duration: 2000,
             
         // Default options for specific types
         success: {
           duration: 2000,
           theme: {
             primary: 'green',
             secondary: 'black',
           },
         },
       }}
    />
    <MsalProvider instance={msalInstance}>
      <Navbar base_url = {base_url} altImage ={altImage} cartItems = {cartItems}  />
      </MsalProvider>
      <Switch>
        <Route exact path='/'>
        <AppContent base_url = {base_url} currency ={currency} altImage ={altImage} banner_url = {banner_url} banner_body_url = {banner_url} banner_url_one ={banner_url_one} banner_url_two={banner_url_two} ProductChevron ={ProductChevron} />
        </Route>
        <Route exact path='/profile'>
        <AccountProfile base_url = {base_url} currency ={currency} altImage ={altImage} />
        </Route>
        <Route exact path='/orders'>
        <AccountOrders base_url = {base_url} currency ={currency} altImage ={altImage} />
        </Route>
        <Route exact path='/checkout'>
        <CheckOut base_url = {base_url} setCartItems = {setCartItems} currency ={currency} altImage ={altImage} onDelete = {onDelete} onRemove = {onRemove} onAdd = {onAdd} cartItems = {cartItems} ProductChevron={ProductChevron}/>
        </Route>
        <Route path='/cart'>
          <Cart altImage={altImage} base_url={base_url} onDelete = {onDelete} onRemove = {onRemove} currency={currency} onAdd = {onAdd} cartItems = {cartItems} ProductChevron={ProductChevron} />
        </Route>
        <Route exact path='/catalog'>
          <AllProducts altImage={altImage} onRemove={onRemove} onAdd = {onAdd} cartItems = {cartItems} base_url ={base_url} currency ={currency} ProductChevron={ProductChevron} />
        </Route>
        <Route exact path='/catalog/:name'>
          <CategoryProducts altImage={altImage} onRemove={onRemove} onAdd = {onAdd} cartItems = {cartItems} base_url ={base_url} currency={currency} ProductChevron={ProductChevron} />
        </Route>
        <Route exact path='/product/:name' >
          <SingleProduct altImage={altImage} onRemove={onRemove} onAdd = {onAdd} base_url ={base_url} currency ={currency} cartItems={ cartItems }  />
        </Route>
        <Route exact path='/payment' >
          <PaymentWaiting altImage={altImage}  base_url ={base_url}  />
        </Route>
        <Route exact path='/orders/:name' >
          <OrderSummary altImage={altImage}  base_url ={base_url}  />
        </Route>
        
        
      </Switch>
      <Footer />
    </div>
    </Router>
    
 );
}

export default App;
